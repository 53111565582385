<template>
  <div class="user-action-container" v-click-outside="() => toggle(false)" :class="{active}">
    <button class="user-action-btn" @click="toggle()">
      <Dots/>
    </button>
    <ul class="user-action-list">
      <li v-if="user.role === 'USER'" class="user-action-item">
        <button class="user-action" @click="loginAs">Login AS</button>
      </li>
      <li v-if="user.role === 'USER'" class="user-action-item">
        <router-link :to="`/dashboard/articles?user=${user.id}`" class="user-action">Articles</router-link>
      </li>
      <li v-if="user.role === 'SUBADMIN' && ['ADMIN', 'SUPERADMIN'].includes(mainUser.role)" class="user-action-item" @click="addUsers">
        <button class="user-action">Add users</button>
      </li>
      <li class="user-action-item">
        <button class="user-action" @click="openLogins">Login history</button>
      </li>
      <!--      <li class="user-action-item">-->
      <!--        <button class="user-action" @click="openActions">Actions</button>-->
      <!--      </li>-->
      <li class="user-action-item">
        <button class="user-action c-red" @click="deleteUser">Delete user</button>
      </li>
    </ul>
  </div>
</template>

<script>
import Dots from '@/assets/icons/dots.svg';
import ClickOutside from "vue-click-outside";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "UsersAction",
  props: ['user'],
  components: {
    Dots
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    ...mapGetters({
      mainUser: 'user'
    }),
  },
  methods: {
    ...mapActions(['setTempUser']),
    toggle(bool = null) {
      this.active = bool === null ? !this.active : bool;
    },
    loginAs() {
      const {id, email, role, ip} = this.user;
      localStorage.setItem('prevUser', JSON.stringify({id, email, role, ip}))
      window.location.reload();
    },
    deleteUser() {
      this.toggle();
      this.setTempUser(this.user);
      this.$modal.show('delete-user-modal')
    },
    openLogins() {
      this.toggle();
      this.setTempUser(this.user);
      this.$modal.show('logins-user-modal');
    },
    // openActions() {
    //   this.toggle();
    //   this.setTempUser(this.user);
    //   this.$modal.show('actions-user-modal');
    // },
    addUsers(){
      this.toggle();
      this.setTempUser(this.user);
      this.$modal.show('add-user-modal')
    }
  }
}
</script>

<style scoped>

</style>
